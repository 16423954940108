import React from 'react';

export const Button = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    textStyles?: string;
    title: string;
    loading?: boolean;
  },
) => {
  const { className, title, loading, ...restProps } = props;

  return (
    <button
      {...restProps}
      disabled={loading}
      className={`inline-block bg-[#6932D4] text-center min-h-[50px] min-w-[210px] text-button rounded-[6px] ${className || ''}`}
    >
      {loading ? <span className="inline-block w-5 h-5 mt-1 border-t border-b rounded-full border-white animate-spin"></span> : title}
    </button>
  );
};
