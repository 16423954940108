import React from 'react';
import { Link } from 'react-router-dom';
// import LaserCardsLogo from '../../../assets/newlogo.svg';
import Xmark from '../../../assets/xmark.svg';

const Navbar = () => {
  return (
    <div className="bg-[#F9F7FF] w-full flex justify-between px-[18px] lg:px-[38px] py-[18px] border-b">
      <div>
        {/* <img src={LaserCardsLogo} alt="laser-card logo" /> */}
      </div>
      <div>
        <Link to="/">
          <img src={Xmark} alt="close" />
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
