import _ from 'lodash';
import moment from 'moment';
import Countdown, { zeroPad } from 'react-countdown';
import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { PoweredBy } from '../components/PoweredBy';
import { PaymentSummary } from '../components/PaymentSummary';
import { useParams } from 'react-router-dom';
import Iphone from '../../../assets/iphone13.svg';
import { hostPayApi } from '../../../api/hostpay';
import { copyText, formatCurrency, generateQrCode, handleRequestErrors } from '../../../utils';
import { PaymentTransactionDto } from '../../../typings/hostpay';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../../typings/reducer';
import { HOSTPAY_TXN_REFRESH_INTERVAL } from '../../../constants';

export const PaymentStatus = (props: { txn: PaymentTransactionDto }) => {
  const { txn } = props;

  const [statusTitle, setStatusTitle] = useState<string>();
  const [statusSummary, setStatusSummary] = useState<string>('');

  const {
    hostpay: { currencies = [] },
  } = useSelector((state: ReducerStates) => state);

  useEffect(() => {
    const currency = currencies.find((curr) => curr.code === txn.currency);

    switch (txn.status) {
      case 'SUCCESS': {
        setStatusTitle('Payment Confirmed');
        setStatusSummary(
          `Your payment of <b>${formatCurrency(
            currency?.type!,
            txn.amount,
            txn.currency,
            currency?.decimals,
          )}</b> was confirmed.<br /> We will now redirect you to the main website.`,
        );
        break;
      }
      case 'EXPIRED': {
        setStatusTitle('Payment Expired');
        // eslint-disable-next-line quotes
        setStatusSummary("Payment wasn't made within set time limit.<br /> We will now redirect you to the main website.");
        break;
      }
      default:
        setStatusTitle(`Payment ${_.capitalize(txn.status)}`);
        setStatusSummary('You will be redirected to the main website');
    }
  }, [txn.status]);

  return (
    <>
      <Navbar />

      <div className="bg-[#F9F7FF] min-h-[calc(100vh-38px)] flex flex-col" style={{ fontFamily: 'manrope' }}>
        <div className="pt-[149px] flex flex-col justify-center flex-1 items-center">
          {txn.status === 'SUCCESS' ? (
            <div className="flex justify-center items-center">
              <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/tick-circle.svg" alt="success icon" />
            </div>
          ) : null}

          <h3 className="text-headline text-black text-center pt-[26px]">{statusTitle}</h3>

          <p
            className="lg:max-w-[400px] text-body-1 text-tertiary text-center pt-[10px] lg:pt-[6px]"
            dangerouslySetInnerHTML={{ __html: statusSummary }}
          />

          <div className="pt-[135px] md:pt-[150px] lg:pt-[38px]">
            <PoweredBy />
          </div>
        </div>

        <div className="flex justify-center">
          <div className="w-[306px] lg:w-[235.04px] pt-[30px] lg:pt-[115px]">
            <div className="flex flex-col justify-center items-center">
              <p className="lg:w-[250px] text-black text-body2 text-center">Enhancing your crypto experience.</p>
              <div className="flex justify-center gap-[24px] pt-[25px] lg:pt-[10px]">
                <a href="https://hostfi-ios.super.site">
                  <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/ios-download-btn.svg" alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.hostfi.app">
                  <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/android-download-btn.svg" alt="" />
                </a>
              </div>
            </div>

            <div className="lg:w-[235.04px] flex justify-center mt-[15px] object-contain">
              <img src={Iphone} alt="iphoneimage" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PaymentProcessing = () => (
  <>
    <Navbar />

    <div className="bg-[#F9F7FF] min-h-[calc(100vh-38px)] flex flex-col" style={{ fontFamily: 'manrope' }}>
      <div className="flex flex-col justify-center items-center h-screen lg:h-full px-[18px] lg:px-0">
        <div className="pt-[60px] lg:pt-[223px] pb-[94px]">
          <div className="flex justify-center items-center">
            <div className="w-10 h-10 border-t-2 border-b-2 rounded-full border-[#6932D4] animate-spin"></div>
          </div>
          <h3 className="text-headline text-black text-center pt-[24px]">We're Processing Your Payment</h3>
          <p className="lg:w-[347px] text-body-1 text-tertiary text-center pt-[6px]">
            We will notify you as soon as your payment has been completed successfully.
          </p>
          <div className="flex justify-center pt-[135px] lg:pt-[184px]">
            <PoweredBy />
          </div>
        </div>
      </div>
    </div>
  </>
);

export const PaymentTransaction = () => {
  const params: any = useParams();
  const [showQRCode, setShowQRCode] = useState(false);
  const [paymentTxn, setPaymentTxn] = useState<PaymentTransactionDto>();
  const [addressQrCode, setAddressQrCode] = useState<string>();
  const [memoQrCode, setMemoQrCode] = useState<string>();
  const [txnCheckTs, setTxnCheckTs] = useState<NodeJS.Timer>();
  const [currentState, setCurrentState] = useState<'PENDING' | 'CONFIRMING'>();

  const {
    hostpay: { currencies = [] },
  } = useSelector((state: ReducerStates) => state);

  const getTxMeta = (name: string, txn?: PaymentTransactionDto) => (txn || paymentTxn)?.metadata.find((meta) => meta.name === name)?.value;

  const getTransactionById = () => {
    hostPayApi
      .getTransactionById(params.id)
      .then((txn) => {
        if (['CANCELED', 'REJECTED', 'FAILED', 'SUCCESS', 'EXPIRED'].includes(txn.status)) {
          setCurrentState(undefined);
        }

        setPaymentTxn(txn);

        const address = getTxMeta('address', txn);
        const memo = getTxMeta('memo', txn);

        generateQrCode(address!).then((code) => setAddressQrCode(code));

        if (memo) {
          generateQrCode(memo).then((code) => setMemoQrCode(code));
        }
      })
      .catch(handleRequestErrors);
  };

  useEffect(() => {
    getTransactionById();

    if (!txnCheckTs) {
      setTxnCheckTs(
        setInterval(() => {
          getTransactionById();
        }, HOSTPAY_TXN_REFRESH_INTERVAL),
      );
    }
  }, []);

  return currentState === 'CONFIRMING' ? (
    <PaymentProcessing />
  ) : ['CANCELED', 'REJECTED', 'FAILED', 'SUCCESS', 'EXPIRED'].includes(paymentTxn?.status!) ? (
    <PaymentStatus txn={paymentTxn!} />
  ) : (
    <>
      <Navbar />

      <div
        className="bg-[#F9F7FF] min-h-[calc(100vh-38px)] flex flex-col-reverse lg:flex-row px-[18px] lg:px-0"
        style={{ fontFamily: 'manrope' }}
      >
        <div className="lg:border-r lg:px-[100px] flex flex-col justify-center items-center">
          <div className="hidden lg:flex flex-col flex-1 justify-center items-center">
            <h2 className="text-headline text-tertiary text-center pt-[40px] lg:pt-[80px] font-[500]">Scan to make payment</h2>

            <div className="flex justify-center items-center mt-[25px] mb-[25px]">
              {addressQrCode ? <img src={addressQrCode} alt="[QR Code]" className="h-[200px]" /> : null}
            </div>

            <p className="lg:w-[300px] text-black text-body-1 text-center pt-[15px]">
              Ensure to click on <span className="text-[#6932D4] text-body-1-bold">"I’ve Made Payment"</span> after you’ve made payment.
            </p>
          </div>

          <div className="w-[306px] lg:w-[235.04px] flex flex-col justify-center items-center pt-[30px] lg:pt-[68px]">
            <div className="flex flex-col justify-center items-center">
              <p className="lg:w-[250px] text-black text-body2 text-center">Enhancing your crypto experience.</p>

              <div className="flex justify-center gap-[24px] pt-[25px] lg:pt-[10px]">
                <a href="https://hostfi-ios.super.site">
                  <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/ios-download-btn.svg" alt="" />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.hostfi.app">
                  <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/android-download-btn.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="w-[306px] flex justify-center lg:w-[235.04px] mt-[15px] object-contain">
              <img src={Iphone} alt="iphoneimage" />
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-center items-center pt-[50px] lg:pt-0">
          <div className="mb-[25px]">
            <p className="text-tertiary text-title text-center">STEP 02 OF 03</p>
            <h2 className="text-headline text-black pt-[4px] text-center">Make your payment</h2>
            <div className="text-tertiary text-title text-center mt-[5px]">
              Pay within{' '}
              <span className="text-[#6932D4] font-bold">
                <Countdown
                  date={moment.utc(paymentTxn?.expiresAt, 'ddd MMM D Y HH:mm:s').toDate()}
                  renderer={({ minutes, seconds }) => `${zeroPad(minutes)}:${zeroPad(seconds)}`}
                />
              </span>
            </div>
          </div>

          <div className="flex justify-center items-center lg:hidden mt-[15px] mb-[25px]">
            {addressQrCode ? <img src={addressQrCode} alt="[QR Code]" className="h-[200px]" /> : null}
          </div>

          {paymentTxn ? (
            <PaymentSummary
              amount={paymentTxn.amount}
              feeAmount={paymentTxn.feeAmount}
              currency={currencies.find((curr) => curr.code === paymentTxn.currency)}
              network={getTxMeta('network')}
              feeBearer={paymentTxn.feeBearer}
            />
          ) : null}

          <div className="lg:w-[538px] pt-[25px]">
            {memoQrCode ? (
              <div className="border-b px-[15px] pb-[16px]">
                <h5 className="text-tertiary text-title">Memo</h5>
                <div className="inline-block cursor-pointer">
                  <div className="relative flex gap-[8px]">
                    <p
                      className="text-black text-body2 border-dashed border-[#000000] border-b-[1px]"
                      onClick={() => copyText(getTxMeta('memo')!)}
                    >
                      {getTxMeta('memo')}
                    </p>

                    <img
                      src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/copy.svg"
                      alt="copy icon"
                      onClick={() => copyText(getTxMeta('memo')!)}
                    />

                    <div
                      onMouseEnter={() => setShowQRCode(true)}
                      onMouseLeave={() => setShowQRCode(false)}
                      className="relative cursor-pointer"
                    >
                      <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/scan-barcode.svg" alt="scan icon" />

                      {showQRCode ? (
                        <div className="absolute bottom-[35px] left-[50%] translate-x-[-50%]">
                          <div className="bg-white w-[130px]">
                            <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/barcode.svg" alt="barcode" />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="pt-[18px] px-[15px]">
              <h5 className="text-tertiary text-title">Address</h5>
              <div className="inline-block" onClick={() => copyText(getTxMeta('address')!)}>
                <div className="flex gap-[8px] cursor-pointer">
                  <p className="text-black text-body2 border-dashed border-[#000000] border-b-[1px]">{getTxMeta('address')}</p>
                  <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/copy.svg" alt="copy icon" />
                </div>
              </div>
            </div>

            <div className="flex justify-center items-center pt-[63px]">
              <button
                className="lg:w-[378px] bg-[#6932D4] text-button py-[14px] px-[77px] rounded-md"
                onClick={() => setCurrentState('CONFIRMING')}
              >
                I've Made Payment
              </button>
            </div>

            <div className="flex justify-center items-center pt-[17px]">
              <p className="lg:w-[312px] text-black text-body-1 text-center">
                Ensure to click on <span className="text-[#6932D4] text-body-1-bold">“I’ve Made Payment”</span> after you’ve made payment.
              </p>
            </div>

            <div className="flex justify-center items-center pt-[63px]">
              <PoweredBy />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
