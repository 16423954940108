import { combineReducers } from 'redux';
import hostpay from './hostpay';
import misc from './misc';

export const appReducer = combineReducers({
  hostpay,
  misc,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STATES') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
