import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import serializeForm from 'form-serialize';
import Navbar from '../components/Navbar';
import { Button } from '../../../components/html/Button';
import { PoweredBy } from '../components/PoweredBy';
import { PaymentSummary } from '../components/PaymentSummary';
import { Input, InputAmount, InputPhoneNumber, Select } from '../../../components/html/Input';
import Iphone from '../../../assets/iphone13.png';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../../typings/reducer';
import { hostPayApi } from '../../../api/hostpay';
import { PaymentCurrencyDto, PaymentLinkDto } from '../../../typings/hostpay';
import { cleanObj, handleRequestErrors } from '../../../utils';

export const PaymentDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    hostpay: { currencies = [] },
  } = useSelector((state: ReducerStates) => state);

  const [selectedCurrency, setSelectedCurrency] = useState<PaymentCurrencyDto>();
  const [paymentLink, setPaymentLink] = useState<PaymentLinkDto>();

  const { id }: any = useParams();

  useEffect(() => {
    hostPayApi.getLinkById(id).then(setPaymentLink).catch(handleRequestErrors);
  }, []);

  useEffect(() => {
    if (paymentLink?.currency) {
      setSelectedCurrency(currencies.find((curr) => curr.code === paymentLink.currency));
    }
  }, [paymentLink]);

  return (
    <>
      <Navbar />

      <div
        className="bg-[#F9F7FF] w-full min-h-[calc(100vh-38px)] flex flex-col px-[4px] lg:px-0 lg:justify-center lg:items-center"
        style={{ fontFamily: 'manrope' }}
      >
        <div className="flex-1 flex-col">
          <p className="text-tertiary text-title pt-[50px] px-[18px] lg:px-0 text-center">STEP 01 of 03</p>
          <h2 className="text-black text-headline pt-[4px] px-[18px] lg:px-0 mb-[25px] text-center">Enter your details to pay</h2>

          <div className="mx-[18px] lg:mx-0">
            {paymentLink?.currency || paymentLink?.amount || paymentLink?.network ? (
              <PaymentSummary
                currency={currencies.find((curr) => curr.code === paymentLink.currency)}
                amount={paymentLink.amount}
                network={paymentLink.network}
                feeBearer={paymentLink.feeBearer}
              />
            ) : null}
          </div>

          <form
            onSubmit={(ev) => {
              ev.preventDefault();

              const form = ev.target as any;
              const { email, phoneNumber = '', currency, network, amount } = serializeForm(form, {
                hash: true,
              });

              const data = cleanObj({
                email: paymentLink?.email || email,
                phoneNumber: paymentLink?.phoneNumber || phoneNumber.replace(/\s/g, '') || undefined,
                currency: paymentLink?.currency || currency,
                network: paymentLink?.network || network,
                amount: paymentLink?.amount || Number(amount),
              });

              setLoading(true);
              hostPayApi
                .createLinkTransaction(id, data)
                .then((response) => navigate(`/pay/transaction/${response.transactionId}`))
                .catch(handleRequestErrors)
                .finally(() => setLoading(false));
            }}
            className="w-full lg:w-[537px] px-[18px] lg:px-0 pt-[22px]"
          >
            <div className="flex flex-col lg:flex-row lg:justify-between gap-[16px] mt-[25px]">
              <Input label="Email Address (Optional)" type="email" name="email" placeholder="Enter email" className="flex flex-col" />

              <InputPhoneNumber
                label="Phone number (Optional)"
                name="phoneNumber"
                autoComplete="off"
                placeholder="+234 901 2348 567"
                className="flex  flex-col"
              />
            </div>

            {!paymentLink?.currency || !paymentLink?.network ? (
              <div className="flex flex-col lg:flex-row lg:justify-between gap-[16px] pt-[16px]">
                {!paymentLink?.currency ? (
                  <div className="flex-1">
                    <Select
                      name="currency"
                      required
                      label="Currency"
                      options={[
                        {
                          label: 'Select one',
                          value: '',
                        },
                        ...currencies.map(({ name, code }) => ({
                          label: name,
                          value: code,
                        })),
                      ]}
                      onChange={(ev) => setSelectedCurrency(currencies.find((curr) => curr.code === ev.target.value))}
                    />
                  </div>
                ) : null}

                {!paymentLink?.network ? (
                  <div className="flex-1">
                    <Select
                      name="network"
                      required
                      label="Network"
                      options={[
                        {
                          label: 'Select one',
                          value: '',
                        },
                        ...(currencies.find(({ code }) => code === selectedCurrency?.code)?.networks || []).map(({ network }) => ({
                          label: network,
                          value: network,
                        })),
                      ]}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="">
              {!paymentLink?.amount ? (
                <InputAmount
                  className="pt-[16px]"
                  required
                  label="Amount"
                  name="amount"
                  type="number"
                  currency={{ type: selectedCurrency?.type, code: selectedCurrency?.code, decimals: selectedCurrency?.decimals } as any}
                />
              ) : null}
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between gap-2 lg:gap-0 pt-[31px]">
              <p className="text-black text-title lg:flex lg:items-center text-center lg:text-start">
                Having trouble?{' '}
                <a href="mailto:support@hostfi.io" className="!text-[#F72585] text-title2 pl-[4px] cursor-pointer">
                  Talk to us
                </a>
              </p>

              <Button loading={loading} title="Submit" type="submit" />
            </div>
          </form>

          <div className="flex justify-center pt-[63px] px-[16px] md:px-[100px] lg:px-0">
            <PoweredBy />
          </div>
        </div>

        <div className="flex justify-center">
          <div className="w-[306px] lg:w-[537px] flex flex-col lg:flex-row lg:justify-between gap-[66px] mt-[36px] border-t pt-[13px] px-[18px] lg:px-0">
            <div className="justify-center flex flex-col items-center">
              <p className="text-black text-body2 text-center px-[10px] lg:px-0">Enhancing your crypto experience.</p>

              <div className="lg:w-[280px] flex justify-center lg:justify-start gap-[14px] pt-[25px] lg:pt-[10px]">
                <a href="https://hostfi-ios.super.site">
                  <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/ios-download-btn.svg" alt="" />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.hostfi.app">
                  <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/android-download-btn.svg" alt="" />
                </a>
              </div>
            </div>

            <div className="flex justify-center">
              <img src={Iphone} alt="iphoneimage" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
