import React, { useState } from 'react';
import serializeForm from 'form-serialize';
import { Link, Element } from 'react-scroll';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { addNewsletterSubscription } from '../api/notifications';
import { handleRequestErrors } from '../utils';
import 'react-toastify/dist/ReactToastify.min.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiTypography-root': {
    background: '#030015',
    color: '#ffffff',
    fontFamily: 'IBM Plex Sans',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingBottom: '30px',
    background: '#030015',
    color: '#ffffff',
    fontFamily: 'Roobert TRIAL',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const LandingPage = () => {
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(false);
  const [showAppInfoDialog, setShowAppInfoDialog] = useState(false);

  return (
    <>
      <BootstrapDialog onClose={() => setShowAppInfoDialog(false)} aria-labelledby="customized-dialog-title" open={showAppInfoDialog}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setShowAppInfoDialog(false)}>
          We Are Launching Soon!
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            At the moment, we are working really hard to bring our mobile apps (Android & iOS) to esteemed customers such as yourself.
          </Typography>

          <Typography gutterBottom>
            As soon as we launch, you will be able to, buy, sell and swap cryptocurrencies, transfer crypto, exchange your local currency
            for crypto via many supported payment methods e.g. Payoneer, Wise, PayPal and so on, right here on HostFi!
          </Typography>

          <Typography gutterBottom>
            <Link
              to="footer"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowAppInfoDialog(false)}
              spy={true}
              smooth={true}
              duration={100}
            >
              <u>Please sign up for our newsletter</u>
            </Link>{' '}
            so you can be one of the first to know as soon as we go live!
          </Typography>
        </DialogContent>
      </BootstrapDialog>

      <div className="landing-wrap">
        <div className="header">
          <div className="container">
            <div className="wrap">
              <div className="logo-area">
                <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/logo.svg" alt="" />
              </div>

              <div className="nav-links">
                <ul>
                  <li>
                    <Link activeClass="active" to="productsAndFeatures" spy={true} smooth={true} duration={100}>
                      Products & Features
                    </Link>
                  </li>
                  <li>
                    <Link activeClass="active" to="legal" spy={true} smooth={true} duration={100}>
                      Legal
                    </Link>
                  </li>
                  <li>
                    <a href="mailto:help@hostfi.io">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section className="hero-area">
          <div className="container">
            <div className="wrap">
              <div className="left">
                <div className="inner">
                  <p className="pre">BUILT WITH YOU IN MIND</p>
                  <h1>Discover new and improved ways to utilize your digital assets</h1>
                  <p className="caption">
                    Enhancing your crypto experience with features that allow you to fully utilize your crypto assets.
                  </p>
                  <div className="app-stores">
                    <a href="https://hostfi-ios.super.site">
                      <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/ios-download-btn.svg" alt="" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.hostfi.app">
                      <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/android-download-btn.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="right"></div>
            </div>
          </div>
        </section>

        <section className="caption-a">
          <h3>All you need in one place.</h3>
          <p className="text-center">Buy, sell, swap crypto or earn interest on your idle assets easily on HostFi</p>
          <div className="illus"></div>
        </section>

        <section id="products" className="product">
          <Element name="productsAndFeatures">
            <div className="container">
              <div className="row">
                <div className="colx col-md-4" id="product-loans">
                  <Element name="product-loans">
                    <div className="contain">
                      <div className="inner">
                        <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/vuesax-money.svg" alt="" />
                        <h4>
                          Loans <span className="coming-soon">Coming Soon</span>
                        </h4>
                        <p>
                          Pay as low as 4% in interest when you borrow in crypto or your local currency using your crypto asset as
                          collateral.
                        </p>
                      </div>
                    </div>
                  </Element>
                </div>

                <div className="colx col-md-4" id="product-savings">
                  <Element name="product-savings">
                    <div className="contain">
                      <div className="inner">
                        <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/vuesax-wallet-money.svg" alt="" />
                        <h4>
                          Savings & Earn <span className="coming-soon">Coming Soon</span>
                        </h4>
                        <p>Earn as much as 10% in interest on your idle balance when you save either individually or as a group.</p>
                      </div>
                    </div>
                  </Element>
                </div>

                <div className="colx col-md-4" id="product-p2p">
                  <Element name="product-p2p">
                    <div className="contain">
                      <div className="inner">
                        <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/vuesax-people.svg" alt="" />
                        <h4>P2P</h4>
                        <p>Make cash deposits or withdrawals directly to your bank account via our network of verified P2P partners.</p>
                      </div>
                    </div>
                  </Element>
                </div>
              </div>

              <div className="row">
                <div className="colx col-md-4" id="product-wallets">
                  <Element name="product-wallets">
                    <div className="contain">
                      <div className="inner">
                        <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/vuesax-empty-wallet.svg" alt="" />
                        <h4>Wallets</h4>
                        <p>Deposit or withdraw either crypto or your local currency with your app.</p>
                      </div>
                    </div>
                  </Element>
                </div>

                <div className="colx col-md-4" id="product-trade">
                  <Element name="product-trade">
                    <div className="contain">
                      <div className="inner">
                        <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/vuesax-receipt-text.svg" alt="" />
                        <h4>Trade</h4>
                        <p>
                          Buy crypto with your local currency, sell crypto for your local currency or swap between two cryptocurrencies
                          instantly on Hostfi.
                        </p>
                      </div>
                    </div>
                  </Element>
                </div>

                <div className="colx col-md-4" id="product-referrals">
                  <Element name="product-referrals">
                    <div className="contain">
                      <div className="inner">
                        <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/vuesax-referrals.svg" alt="" />
                        <h4>Referrals</h4>
                        <p>
                          Refer your friends earn up to 10 USDT for each referral when they maintain a minimum balance of 100 USDT for up to
                          30days.
                        </p>
                      </div>
                    </div>
                  </Element>
                </div>
              </div>

              <div className="row">
                <div className="colx col-md-4" id="product-cards">
                  <Element name="product-cards">
                    <div className="contain">
                      <div className="inner">
                        <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/vuesax-card.svg" alt="" />
                        <h4>
                          Cards
                        </h4>
                        <p>
                          Get a virtual USD card on HostFi. Fund for card with your local currency or crypto. You can even fund your card
                          with crypto backed loans.
                        </p>
                      </div>
                    </div>
                  </Element>
                </div>

                <div className="colx colx-last col-md-4" id="product-pay">
                  <Element name="product-hostpay">
                    <div className="contain">
                      <div className="inner">
                        <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/vuesax-wallet-add.svg" alt="" />
                        <h4>
                          Host+ Pay
                        </h4>
                        <p>Embed Host+ Payment widget on your website and receive payments directly to your wallet from your oustomers.</p>
                      </div>
                    </div>
                  </Element>
                </div>

                <div className="colx col-md-4"></div>
              </div>
            </div>

            <div className="illus-coins-box"></div>
          </Element>
        </section>

        <section className="caption-b">
          <div className="container">
            <div className="wrap">
              <div className="wrap-content">
                <div className="left">
                  <div className="inner">
                    <h1 style={{ margin: 0, paddingBottom: 15 }}>It's really quite easy</h1>
                    <p>
                      With HostFi, you can sell or buy crypto with your local currency, swap from one crypto currency to another, borrow
                      against your crypto assets, earn as much as 10% interest on idle assets and many more.
                    </p>
                    <div className="app-stores">
                      <a href="https://hostfi-ios.super.site">
                        <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/ios-download-btn.svg" alt="" />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.hostfi.app">
                        <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/android-download-btn.svg" alt="" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="illus"></div>
              </div>
            </div>
          </div>
        </section>

        <section className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <a className="logo-area">
                  <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/logo.svg" alt="" />
                </a>
                <p className="copyr">&copy;{currentYear} HostFi Services.</p>
                <ul className="social-links">
                  <li>
                    <a href="https://www.linkedin.com/company/86817185/admin/" target="_blank" rel="noopener noreferrer">
                      <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/u_linkedin.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://web.facebook.com/profile.php?id=100087794615843" target="_blank" rel="noopener noreferrer">
                      <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/u_facebook.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/hostfi_app/" target="_blank" rel="noopener noreferrer">
                      <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/u_instagram.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/hostfi_app" target="_blank" rel="noopener noreferrer">
                      <img src="https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/hostfi/u_twitter.svg" alt="" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col">
                    <ul className="nav-links">
                      <li className="title">Products & Features</li>
                      <li>
                        <a href="https://hostcap.io" target="_blank" rel="noopener noreferrer">
                          Business
                        </a>
                      </li>
                      <li>
                        <Link activeClass="active" to="product-p2p" spy={true} smooth={true} duration={100}>
                          P2P
                        </Link>
                      </li>
                      <li>
                        <Link activeClass="active" to="product-loans" spy={true} smooth={true} duration={100}>
                          Loans
                        </Link>
                      </li>
                      <li>
                        <Link activeClass="active" to="product-hostpay" spy={true} smooth={true} duration={100}>
                          Host+ Pay
                        </Link>
                      </li>
                      <li>
                        <Link activeClass="active" to="product-cards" spy={true} smooth={true} duration={100}>
                          Cards
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col">
                    <Element name="legal">
                      <ul className="nav-links">
                        <li className="title">Legal</li>
                        <li>
                          <a href="https://hostfi.super.site/terms">Terms of Use</a>
                        </li>
                        <li>
                          <a href="https://hostfi.super.site/privacy">Privacy & Security</a>
                        </li>
                        <li>
                          <a href="https://hostfi.super.site/compliance">AML Policy</a>
                        </li>
                        <li>
                          <a href="https://hostfi.super.site/compliance">CTF Policy</a>
                        </li>
                      </ul>
                    </Element>
                  </div>

                  <div className="col-md-4">
                    <ul className="nav-links">
                      <li className="title">Help</li>
                      <li>
                        <a href="https://hostfiapp.medium.com/">Blog</a>
                      </li>
                      <li>
                        <a href="https://hostfi.super.site/faqs">FAQs</a>
                      </li>
                      <li>
                        <a href="mailto:help@hostfi.io">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="newsletter-sync">
                  <p>Sign up for our newsletter to get priority notifications on our product and services</p>

                  <form
                    onSubmit={(ev) => {
                      ev.preventDefault();

                      const form = ev.target as any;
                      const { fullName, email } = serializeForm(form, { hash: true });

                      setLoading(true);
                      addNewsletterSubscription(fullName, email)
                        .then(() => {
                          // eslint-disable-next-line quotes
                          toast.success("You've been successfully subscribed to our newsletter!");
                          form.reset();
                        })
                        .catch((err) => handleRequestErrors(err))
                        .finally(() => setLoading(false));
                    }}
                  >
                    <input required={true} type="email" name="email" placeholder="Enter your email address" minLength={5} maxLength={80} />
                    <button disabled={loading}>Send</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
