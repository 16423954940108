import { MiscState, MiscAction } from '../typings/misc';

const defaultState: MiscState = {};

export default (state = defaultState, action: MiscAction): MiscState => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
