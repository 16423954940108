import React from 'react';
import Arrow from '../../assets/arrowDown.svg';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import { formatCurrency } from '../../utils';
import { CurrencyDefinition } from '../../typings/rates';

interface InputProps {
  label?: string;
  labelClassName?: string;
  inputClassName?: string;
}

export const Input = (props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & InputProps) => {
  const { label, className = '', labelClassName = '', inputClassName = '', ...restProps } = props;

  return (
    <div className={className}>
      <label className={`text-tertiary text-title pb-[10px] ${labelClassName}`} htmlFor={restProps.id}>
        {label}
      </label>

      <input
        {...restProps}
        className={`bg-transparent lg:w-[262px] px-3 py-[12px] border-1 border-inputColor rounded-lg text-inputColor outline-none ${inputClassName}`}
      />
    </div>
  );
};

export const InputAmount = (
  props: InputProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { currency?: CurrencyDefinition },
) => {
  const { id, disabled, label, currency, name, className = '', labelClassName = '', inputClassName = '', required } = props;

  return (
    <div className={className}>
      <label className={`text-tertiary text-title pb-[10px] ${labelClassName}`} htmlFor={props.id}>
        {label}
      </label>

      <Cleave
        key={`${currency?.code}-input-amount`}
        required={required}
        disabled={disabled}
        id={id}
        name={name}
        className={`bg-[#F9F7FF] w-full px-3 py-[18px] border-1 border-inputColor rounded-lg text-black outline-none ${inputClassName}`}
        placeholder={currency ? `${formatCurrency(currency?.type!, 0, currency?.code!, currency?.decimals)}` : '0.00'}
        autoComplete="off"
        options={{
          numeral: true,
          numeralDecimalScale: currency?.decimals,
          stripLeadingZeroes: false,
          numeralThousandsGroupStyle: 'thousand',
        }}
      />
    </div>
  );
};

export const InputPhoneNumber = (
  props: InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) => {
  const {
    id,
    disabled,
    label,
    placeholder,
    name,
    autoComplete,
    className = '',
    labelClassName = '',
    inputClassName = '',
    required,
  } = props;

  return (
    <div className={className}>
      <label className={`text-tertiary text-title pb-[10px] ${labelClassName}`} htmlFor={id}>
        {label}
      </label>

      <Cleave
        id={id}
        name={name}
        required={required}
        disabled={disabled}
        autoComplete={autoComplete}
        className={`bg-transparent lg:w-[262px] px-3 py-[12px] border-1 border-inputColor rounded-lg text-inputColor outline-none ${inputClassName}`}
        placeholder={placeholder || 'E.g 8051235678'}
        options={{
          phone: true,
          phoneRegionCode: 'any',
        }}
      />
    </div>
  );
};

export const TextArea = (
  props: InputProps & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
) => {
  const { label, className = '', labelClassName = '', inputClassName = '', ...restProps } = props;

  return (
    <div className={className}>
      <label className={`text-tertiary text-title pb-[10px] ${labelClassName}`} htmlFor={props.id}>
        {label}
      </label>

      <textarea
        {...restProps}
        className={`bg-transparent w-full px-3 py-2 border-1 border-inputColor rounded-lg text-black outline-none ${inputClassName}`}
      />
    </div>
  );
};

export const Select = (
  props: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    label?: string;
    labelClassName?: string;
    options?: { value: string; label: string }[];
  },
) => {
  const { label, options = [], className = '', labelClassName = '', ...restProps } = props;

  return (
    <div className={className}>
      <label className={`text-tertiary text-title pb-[10px] ${labelClassName}`} htmlFor={props.id}>
        {label}
      </label>

      <div className="relative w-full flex border-1 border-inputColor rounded-md">
        <select {...restProps} className="bg-transparent w-full outline-none text-inputColor pl-2 py-[12px] appearance-none z-30 cursor-pointer px-3">
          {options.map(({ value, label }, index) => (
            <option key={index} value={value} className="text-body">
              {label}
            </option>
          ))}
        </select>
        <img src={Arrow} alt="selectArrow" className="absolute right-[15px] top-[50%] h-[10px] mt-[-5px]  cursor-pointer" />
      </div>
    </div>
  );
};
