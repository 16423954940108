import qrcode from 'qrcode';
import copy from 'clipboard-copy';
import BigNumber from 'bignumber.js';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { CurrencyType } from '../typings/rates';
import { StableCoins, USD } from '../constants';

export const handleRequestErrors = (error: AxiosError, defaultMessage?: string) => {
  let useMessage = '';

  if (error && error.response && error.response.status.toString().startsWith('4')) {
    const errors = (error.response as any).data.errors;
    useMessage = (error.response as any).data.message || (errors && errors[0].message) || defaultMessage || 'An unexpected error occurred.';
  }

  toast.error(useMessage);
};

export const normaliseAmount = (decimals: number, value: number): number => {
  return Number(Math.floor(parseFloat(value + 'e' + decimals)) + 'e-' + decimals);
};

export const formatCurrency = (type: CurrencyType, amount: number, currency: string, decimals = 8): string => {
  decimals = decimals > 8 ? 8 : decimals;
  decimals = StableCoins.includes(currency) ? 2 : decimals;
  amount = normaliseAmount(decimals, Number(new BigNumber(amount).toFixed(decimals, BigNumber.ROUND_DOWN)));

  if (type === 'CRYPTO') {
    return `${amount} ${currency}`;
  } else {
    return amount.toLocaleString('en-US', { style: 'currency', currency: currency || USD });
  }
};

export const generateQrCode = (text: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    qrcode.toDataURL(text, (err, imageUri) => {
      if (err) {
        return reject(err);
      }

      resolve(imageUri);
    });
  });
};

export const cleanObj = (obj: any): any => {
  for (const propName in obj) {
    if (obj[propName] === undefined) {
      delete obj[propName];
    }
  }

  return obj;
};

export const copyText = (text: string): void => {
  copy(text).then(() => {
    toast.info(`${text} copied!`);
  });
};
