import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';
import { PaymentDetails } from './pages/hostpay/PaymentDetails';
import { PaymentTransaction } from './pages/hostpay/PaymentTransaction';
import { LandingPage } from './pages/LandingPage';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.scss';
import { useDispatch } from 'react-redux';
import { hostPayApi } from './api/hostpay';
import { hostPayActions } from './actions/hostpay';

export const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    hostPayApi.getCurrencies().then((response) => dispatch(hostPayActions.setSupportedCurrencies(response.currencies)));
  }, []);

  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar={true} closeButton={<>✕</>} position="top-center" />

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/pay/:id" element={<PaymentDetails />} />
        <Route path="/pay/transaction/:id" element={<PaymentTransaction />} />
        <Route path="/referral/:id" element={<LandingPage />} />
      </Routes>
    </>
  );
};
