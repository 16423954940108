import React from 'react';
import { Provider } from 'react-redux';
import { DevTools } from './DevTools';
import { withErrorBoundary } from '../helpers/ErrorBoundary';
import { App } from '../App';

const ErrorMessageWithErrorBoundary = withErrorBoundary(() => <></>);

export const Root = ({ store }: any): JSX.Element => (
  <>
    <Provider store={store}>
      <ErrorMessageWithErrorBoundary>
        <App />
      </ErrorMessageWithErrorBoundary>

      {process.env.NODE_ENV === 'development' && <DevTools />}
    </Provider>
  </>
);
