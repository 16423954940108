import { HostPayActions, HostPayState } from '../typings/hostpay';

const initialState: HostPayState = {
  currencies: [],
};

export default (state = initialState, action: HostPayActions): Partial<HostPayState> => {
  switch (action.type) {
    case 'SET_SUPPORTED_CURRENCIES':
      return {
        ...state,
        currencies: action.currencies,
      };
    default:
      return state;
  }
};
