import Axios from 'axios';
import { StatusDto } from '../typings';

export const addNewsletterSubscription = async (fullName: string, email: string): Promise<StatusDto> => {
  return (
    await Axios.post(`${process.env.REACT_APP_API_URL}/notifications/newsletter/subscriptions`, {
      fullName,
      email,
      product: 'HOSTFI',
    })
  ).data;
};
