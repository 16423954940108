import config from '../config';
import Request from '../helpers/request';
import {
  PaymentAddressDto,
  PaymentCurrencyListResponse,
  PaymentLinkDto,
  PaymentTransactionDto,
  PaymentTransactionRequestDto,
} from '../typings/hostpay';

export const hostPayApi = {
  async getCurrencies(): Promise<PaymentCurrencyListResponse> {
    return await Request.get<PaymentCurrencyListResponse>(`${config.api.hostpay}/currencies`);
  },

  async getTransactionById(id: string): Promise<PaymentTransactionDto> {
    return await Request.get<PaymentTransactionDto>(`${config.api.hostpay}/transactions/${id}`);
  },

  async getLinkById(id: string): Promise<PaymentLinkDto> {
    return await Request.get<PaymentLinkDto>(`${config.api.hostpay}/links/${id}`);
  },

  async createLinkTransaction(id: string, data: Partial<PaymentTransactionRequestDto>): Promise<PaymentAddressDto> {
    return await Request.post<PaymentAddressDto>(`${config.api.hostpay}/links/${id}/transactions`, { data });
  },
};
