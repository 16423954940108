import React from 'react';
import { PaymentCurrencyDto } from '../../../typings/hostpay';
import { formatCurrency } from '../../../utils';

interface PaymentSummaryProps {
  amount?: number;
  feeAmount?: number;
  currency?: PaymentCurrencyDto;
  network?: string;
  feeBearer?: 'INTERNAL' | 'EXTERNAL';
}

export const PaymentSummary = (props: PaymentSummaryProps) => {
  const { amount, feeAmount = 0, currency, network, feeBearer = 'INTERNAL' } = props;

  return (
    <div className="w-full lg:w-[538px] bg-background-01 rounded-md bg-opacity-10 mt-2 lg:mt-0 lg:mt-[36px] px-[18px] lg:px-0">
      <div className=" flex justify-between items-center lg:px-[26px] py-[16px]">
        {currency && amount ? (
          <div className="w-full text-left">
            <div className="inline-block align-top">
              <p className="text-tertiary text-title">Amount {feeBearer === 'EXTERNAL' ? '(+ Fee)' : ''}</p>
              <div className="flex pt-[6px]">
                <h4 className="text-black text-headline ">
                  {formatCurrency(currency.type, amount + (feeBearer === 'EXTERNAL' ? feeAmount : 0), currency.code)}
                </h4>
              </div>
            </div>
          </div>
        ) : null}

        {currency ? (
          <div className="w-full text-left">
            <div className="inline-block align-top">
              <p className="text-tertiary text-title">Currency</p>
              <div className="flex pt-[6px] align-items-center gap-[5px]">
                <img
                  src={require(`../../../assets/currencies/${currency.code.toLowerCase()}.svg`)}
                  alt="currency-logo"
                  className="w-[20px] h-[20px]"
                />
                <h4 className="text-black text-headline">{currency.code}</h4>
              </div>
            </div>
          </div>
        ) : null}

        {network ? (
          <div className="w-full text-left">
            <div className="inline-block align-top">
              <p className="text-tertiary text-title">Network</p>
              <div className="flex pt-[6px]">
                <h4 className="text-black text-headline">{network}</h4>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
