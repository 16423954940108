import React from 'react';

export const PoweredBy = () => {
  return (
    <div className="w-[303px]">
      <p className="bg-white  border-1 border-[#6932D4] rounded-md px-[61px] py-[11px] text-black text-title2 text-center">
        Powered & secured by <span className="text-[#6932D4] text-title">HostFi</span>
      </p>
    </div>
  );
};
